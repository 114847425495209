<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer2"
    :light="
      barCourseColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'
    "
    :expand-on-hover="expandOnHover"
    :right="true"
    mobile-breakpoint="960"
    app
    width="480"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barCourseColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="display-1 font-weight-bold"
            v-text="'Conteúdo do Curso'"
          />
        </v-list-item-content>

        <v-btn
          class="mr-3"
          elevation="0"
          text
          icon
          @click="setDrawer(!drawer2)"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

    <v-divider class="mb-0" />

    <v-list expand nav id="ementa">
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :text="false"
          :item="item"
          :disabled="isExpirado()"
        >
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" :disabled="isExpirado()"/>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { db } from "@/db";
export default {
  name: "CoursesCoreDrawer",
  firebase() {
    const ementa = "/cursos/" + this.$route.params.curso + "/ementa/";
    const aula = "/cursos/" + this.$route.params.curso + "/aulas/" + this.$route.params.id;
    const aulasUsuario = "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso + "/aulas/"
    const ref = "/cursos/" + this.$route.params.curso;
    const matriculaRef = "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso;
    // const detalhes = "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso + "/aulas/";
    return {
      items: db.ref(ementa),
      itemsUsuario: db.ref(aulasUsuario),
      curso: db.ref(ref),
      matricula: db.ref(matriculaRef),
    };
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    items (to, from) {

    }
  },
  data: () => ({
    items: [],
    itemsUsuario:[],
    detalhes: [],
    items2: [
      {
        title: "Módulo 1",
        curso: true,
        duracao: "6 horas",
        icon: 'mdi-arrow-right-drop-circle',
        text: false,
        group: "",
        children: [
          {
            icon: 'mdi-arrow-right-drop-circle',
            title: "Tira Dúvidas Ao Vivo",
            subtitle: "1 horas",
            assistido: true,
            to: "courses/course/aulas/wtwtKXUkimU",
            video: true,
          },
          {
            icon: 'mdi-arrow-right-drop-circle',
            title: "Aula 1",
            subtitle: "5 horas",
            to: "courses/course/aulas/sMPAr_XoTik",
            video: true,
          },
        ],
      },
      {
        title: "Módulo 2",
        icon: 'mdi-arrow-right-drop-circle',
        group: "",
        curso: true,
        duracao: "5 horas",
        children: [
          {
            icon: 'mdi-arrow-right-drop-circle',
            title: "Aula 2",
            video: true,
            subtitle: "5 horas",
            to: "courses/course/aulas/5aSI7GH7d-c",
          },
        ],
      },
      /*{
          icon: 'mdi-account',
          title: 'user',
          to: '/pages/user',
        },
        {
          icon: 'mdi-account',
          title: 'player',
          to: '/courses/course',
          beforeEnter: (to, from, next) => {
            this.$store.state.courseTitle=false
          }
        },
        {
          title: 'rtables',
          icon: 'mdi-clipboard-outline',
          to: '/tables/regular-tables',
        },
        {
          title: 'typography',
          icon: 'mdi-format-font',
          to: '/components/typography',
        },
        {
          title: 'icons',
          icon: 'mdi-chart-bubble',
          to: '/components/icons',
        },
        {
          title: 'google',
          icon: 'mdi-map-marker',
          to: '/maps/google-maps',
        },
        {
          title: 'notifications',
          icon: 'mdi-bell',
          to: '/components/notifications',
        },*/
    ],
  }),

  computed: {
    ...mapState(["barCourseColor", "showPlayer", "videoId","user"]),
    drawer2: {
      get() {
        return this.$store.state.drawer2;
      },
      set(val) {
        this.$store.commit("SET_DRAWER2", val);
      },
    },
    computedItems() {
      // console.log(this.items)
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    ...mapMutations({
      setShowPlayer: "SET_PLAYER_SHOW",
      setDrawer: "SET_DRAWER2",
    }),
    isExpirado () {
      const dataAtual = new Date();
      const novaDataLimite = this.getDataLimite();
      return dataAtual > novaDataLimite;
    },
    getDataLimite () {
      const dataMatricula = new Date(this?.matricula?.dataMatricula);
      const dataMatriculaLimite = new Date(this?.matricula?.dataLimite);
      const dataLimite = new Date(this?.curso?.dataLimite);
      const permanencia = parseInt(this?.curso?.permanencia);

      let novaDataLimite;
      if (!isNaN(dataMatriculaLimite)) {
        novaDataLimite = dataMatriculaLimite;
      } else if (!isNaN(dataMatricula.getTime())) {
        novaDataLimite = new Date(dataMatricula);
        novaDataLimite.setDate(novaDataLimite.getDate() + permanencia * 7);
      } else if (!isNaN(dataLimite)) {
        console.log('Data Limite: ',dataLimite);
        novaDataLimite = dataLimite;
      } else {
        novaDataLimite = new Date();
      }
      //
      return novaDataLimite;
    },
    mapItem(item) {
      let dateNow = new Date();
      let dateItem= item.dataPublicacao ? new Date(item.dataPublicacao):dateNow;

      if(item){
        if(item.children){
          item.children = Array.isArray(item.children) ? item.children: this.getValues(item.children)
        }else{
          item.children = [];
        }
        
        // console.log(item)
      let data= {
        ...item,
        //children: item.children ? 'item.children.map(this.mapItem)' : undefined,
        children: item.children ? item.children.map(this.mapItemChildren) : undefined,
        title: this.$t(item.title),
        publicado: (dateNow.getTime() >= dateItem.getTime())
      };
      //  console.log(data)
      return data;
      }else{
        return null;
      }
      
    },
    getValues(object){
      // console.log(object)
      let keys = Object.keys(object)
      let values = Object.values(object)

      keys.forEach(function(key,index){
        values[index].hash = key
      })
      // console.log(keys)
      // console.log(values)
      // console.log(keys[0])
      return values;
    },
    mapItemChildren(item){
      // console.log(item)
      let url = "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso + "/aulas/"+item.hash;
      let ref = db.ref(url)
      item.assistido = false
      this.itemsUsuario.forEach((element)=>{
        if(element['.key']==item.hash){
          item.assistido = element.assistido;
        }
      })
      ref.on('value', (snapshot) =>{
        if(snapshot.val()){
          item.assistido = snapshot.val().assistido || false
          // console.log(item)
        }
      });
      
      return {
        ...item,
        title: this.$t(item.title),
        to: 'curso/'+this.$route.params.curso+'/aula/'+item.hash
      }
    },
    sair() {
      localStorage.removeItem("user");
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.reload();
          // console.log('Courses')
          // this.$router.replace("Login");
        });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  #ementa.v-list.v-sheet.theme--light.v-list--nav
    background-color: rgba(51,51,51,0.1)

  .v-list-group__items .v-list-item:not(:last-child),
  .v-list-group:not(:last-child)
    border-bottom:1px solid #dedfe0

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-group--active > .v-list-group__header .v-list-item, 
  .v-list-group--active > .v-list-group__header .v-list-item__content, 
  .v-list-group--active > .v-list-group__header .v-list-group__header__prepend-icon .v-icon
    color: rgba(0, 0, 0, 0.87)

    .theme--light.primary.v-list-item--active
      color: white !important

    .v-list-group--active.white--text
      color: #333333 !important

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 18px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0
        

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
</style>
